
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Tooltip from "@/shared/components/cards/Tooltip.vue";
import { makeToast } from "@/shared/utils/toast";
import { copyText } from "vue3-clipboard";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    enableCopyToClipboard: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const t = useI18n().t;

    const copyToClipboard = (e) => {
      if (e?.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }

      copyText(props.content, undefined, (error, event) => {
        if (error) {
          console.log(error);
          makeToast("error", t("Error"), t(`Error copying to clipboard.`));
        } else {
          console.log(event);
          makeToast(
            "success",
            t("Success"),
            t(`You have successfully copied to clipboard.`),
            1
          );
        }
      });
    };

    return {
      t,
      copyToClipboard,
    };
  },
  components: {
    Tooltip,
  },
});
